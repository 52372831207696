const views = {
    Monitor: {
        ExaminationRoomMonitor: {
            Index: 'ExaminationRoomMonitorIndex',
        },
        ExaminationSiteMonitor: {
            Index: 'ExaminationSiteMonitorIndex'
        },
        ExaminationCityMonitor: {
            Index: 'ExaminationCityMonitorIndex'
        },
    },
    Organization: {
        List: {
            Index: 'OrganizationIndex'
        },
        Detail: 'User',
        BaseConfig: 'BaseConfig'
    },
    ExamCsp: {
        Exam: {
            List: 'ExamList',
        },
        Playback: {
            Index: 'ExamCspPlaybackIndex',
        },
        ExaminationRoom: {
            List: 'ExaminationRoomList',
            Details: 'ExaminationRoomDetails',
        },
        ExaminationDistrict: {
            List: 'ExaminationDistrictList',
        },
        ExaminationReport: {
            EquipmentDistrict: 'ExaminationReportEquipmentDistrict',
            EquipmentRoom: 'ExaminationReportEquipmentRoom',
            EquipmentSite: 'ExaminationReportEquipmentSite',
            MonitorDistrict: 'ExaminationReportMonitorDistrict',
            MonitorRoom: 'ExaminationReportMonitorRoom',
            MonitorSite: 'ExaminationReportMonitorSite',
            RegsiterDistrict: 'ExaminationReportRegsiterDistrict',
            RegsiterDistrictException: 'ExaminationReportRegsiterDistrictException',
            RegsiterSite: 'ExaminationReportRegsiterSite',
            RegsiterRoom: 'ExaminationReportRegsiterRoom',
            Index: 'ExaminationReportIndex'
        },
        ExaminationSite: {
            List: 'ExaminationSiteList',
        },
        SystemConfig: {
            Index: 'SystemConfigIndex',
        },
    },
    ExaminationAdmin: {
        ExaminationSite: {
            List: 'ExaminationAdminExaminationSiteList',
        },
        ExaminationRoom: {
            List: 'ExaminationAdminExaminationRoomList',
            Details: 'ExaminationAdminExaminationRoomDetails',
        },

    },
    Index: 'Index',
    Demo: 'Demo',
    Login: 'Login',
    ExaminationRoomMonitorView: 'ExaminationRoomMonitorView',

};

export default views;