<template>
    <div>
        <div class="header d-flex primary">
            <div>
                <h3 class="title pt-2 pl-2">卓帆视频监控云服务平台</h3>
            </div>
            <div class="ml-auto">
                <v-tabs ground-color="primary" class="subtitle-1" dark right>
                    <v-tab :to="$to($views.ExaminationAdmin.ExaminationSite.List)">
                        考点管理
                    </v-tab>
                </v-tabs>
            </div>
            <div class="my-auto yellow--text caption d-flex">
                <p class="mr-n2 mb-0 pt-3">{{this.areaName}}，欢迎您</p>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" text fab small @click="changePasswordDialog = true">
                            <v-icon color="white">mdi-square-edit-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>修改密码</span>
                </v-tooltip>
            </div>
            <div class="login-out ml-n3">
                <v-menu offset-y bottom>
                    <template v-slot:activator="{ on }">
                        <v-avatar class="login-out white--text" v-on="on">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" text fab small>
                                        <v-icon color="white">mdi-power-standby</v-icon>
                                    </v-btn>
                                </template>
                                <span>退出登录</span>
                            </v-tooltip>
                        </v-avatar>
                    </template>
                    <v-list>
                        <v-list-item @click="loginOut()">
                            <v-list-item-title>退出登录</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div class="main scroll examCsp-main">
            <router-view />
        </div>

        <v-footer fixed color="primary">
            <v-col cols="12" class="text-center pa-0 white--text">
                技术支持：深圳市卓帆技术有限公司
            </v-col>
        </v-footer>

        <v-dialog max-width="500" persistent v-model="changePasswordDialog">
            <password @passwordBack="passwordBack"></password>
        </v-dialog>
    </div>
</template>

<script>
    import password from '@/components/changePassword/password';
    import { Vue, Component, Inject } from 'vue-property-decorator';
    import Storage from "../../domain/storage";
    import keys from "../../domain/keys";

    export default @Component({ components: { password } })
    class ExaminationAdminLayout extends Vue {
        @Inject()
        repository;
        @Inject()
        navigation;
        @Inject()
        user;

        areaName='';
        changePasswordDialog = false;


        created() {
            if(Storage.Get(keys.REGISTRY_INFO)!=undefined&&Storage.Get(keys.REGISTRY_INFO)!=null){
                this.areaName=JSON.parse(Storage.Get(keys.REGISTRY_INFO)).name;
            }
        }
        getParams(url) {
            var pms= {};
            if (url.indexOf('?') != -1) {
                var temp1 = url.split('?');
                var pram = temp1[1];
                var keyValue = pram.split('&');
                for (var i = 0; i < keyValue.length; i++) {
                var item = keyValue[i].split('=');
                var key = item[0];
                var value = item[1];
                pms[key] = value;
                }
            }
            return pms
        }


        loginOut() {
            //清楚token
            const searchParam = localStorage.getItem('searchParam')
            let obj = {}
            let param = {}
            if(searchParam.length > 0 && searchParam!== undefined){
                obj = this.getParams('?'+searchParam )
                param = { ...obj };
            }
            this.user.Logout();
            this.navigation.redirect(window.$views.Login,param);
        }
        passwordBack(){
            this.changePasswordDialog = false;
        }

    }
</script>

<style scoped>
    .v-application--is-ltr .v-tabs--right>.v-tabs-bar .v-tab:last-child{
        background: #3d9afe;
    }
</style>
