import sender from '../request';
export default class ExaminationReportRepository {

    GetExaminationRoomReport(examId,sitecode, pageindex, pagesize, roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationRoomReport?sitecode=" + sitecode + "&pageindex=" + pageindex + "&pagesize=" + pagesize + "&roomType=" + roomType+ "&examId=" + examId )
    }
    GetExaminationSiteReport(examId,areacode, roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationSiteReport?areacode=" + areacode + "&roomType=" + roomType+"&examId=" + examId)
    }
    GetExaminationAreaReport(examId,roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationAreaReport?roomType=" + roomType+ "&examId=" + examId)
    }

    GetUnMonitorRoom(examId) {
        return sender.get("/ExaminationReport/GetUnMonitorRoom?examId="+ examId)
    }

    GetUnRegisterRoom(examId) {
        return sender.get("/ExaminationReport/GetUnRegisterRoom?examId="+ examId)
    }


    GetUnReportRoom(examId) {
        return sender.get("/ExaminationReport/GetUnReportRoom?examId="+ examId)
    }



    GetExaminationReport(examId,roomType) {
        return sender.getWithUnCatch("/ExaminationReport/GetExaminationReport?roomType=" + roomType + "&examId=" + examId)
    }
}
