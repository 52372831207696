<template>
    <div>
        <v-row no-gutters class="my-1">
            <v-col>
                <h3 class="text-center">{{ this.context.name }}</h3>
            </v-col>
            <v-col cols="auto">
                <v-btn
                    outlined
                    color="primary"
                    @click="back"
                >
                    返回
                </v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="pa-4">
            <v-data-table
                :headers="[
                    {
                        text: '设备编号',
                        value: 'equipmentNo',
                        width: '100px',
                    },
                    {
                        text: '监控状态',
                        value: 'monitorStatus',
                        width: '100px',
                    },
                    {
                        text: '在线状态',
                        value: 'onlineStatus',
                        width: '100px',
                    },
                    {
                        text: '设备状态',
                        value: 'equipmentStatus',
                        width: '100px',
                    },
                    {
                        text: '最后在线时间',
                        value: 'lastOnlineTime',
                        width: '100px',
                    },
                ]"
                :items="equipments"
                no-data-text="暂无数据"
                :items-per-page="10"
            ></v-data-table>
        </div>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Loading } from '@/infrastructures/decorators/Loading';
export default
@Component({})
class ExaminationRoomDetails extends Vue {
    static name = window.$views.ExamCsp.ExaminationRoom.Details;
    @Inject()
    repository;
    @Inject()
    loader;

    equipments = [];

    get centreNo() {
        return this.$route.query.centreNo;
    }

    get examId(){
        return this.$route.query.examId;
    }

    mounted() {
        this.GetEquipmentDetails();
    }
    back() {
        this.$router.go(-1);
    }

    @Loading('数据加载中...')
    GetEquipmentDetails() {
        this.repository.ExaminationRoom.GetEquipmentDetails({
            centreNo: this.centreNo,
            examId:this.examId
        }).then((res) => {
            this.equipments = res.data.equipments;
        });
    }
}
</script>
