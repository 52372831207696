<template>
    <div>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="8" size="120">
                数据加载中
            </v-progress-circular>
        </v-overlay>
        <v-row justify="center" class="text-center my-4" no-gutters>
            <v-col>
                <v-progress-circular
                    v-model="registerRate"
                    v-bind="progressProps"
                >
                    <v-list-item-content>
                        <v-list-item-title class="text-center display-1">
                            {{ registerRate }}%
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                            考场注册进度
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-center">
                            <v-btn text @click="ViewRegsiter">查看详情</v-btn>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-center">
                            <v-btn text @click="ViewException(1)">异常情况</v-btn>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-progress-circular>
            </v-col>
            <v-col>
                <v-progress-circular
                    v-model="ReadedRate"
                    v-bind="progressProps"
                >
                    <v-list-item-content>
                        <v-list-item-title class="text-center display-1">
                            {{ ReadedRate }}%
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                            考场上报进度
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-center">
                            <v-btn text @click="ViewReaded">查看详情</v-btn>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-center">
                            <v-btn text @click="ViewException(2)">异常情况</v-btn>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-progress-circular>
            </v-col>
            <v-col>
                <v-progress-circular
                    v-model="MonitoringRate"
                    v-bind="progressProps"
                >
                    <v-list-item-content>
                        <v-list-item-title class="text-center display-1">
                            {{ MonitoringRate }}%
                        </v-list-item-title>
                        <v-list-item-subtitle class="text-center">
                            考场监控情况
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-center">
                            <v-btn text @click="ViewMonitor">查看详情</v-btn>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-center">
                            <v-btn text @click="ViewException(3)">异常情况</v-btn>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-progress-circular>
            </v-col>
        </v-row>
        <div class="statistics-table mt-10">
            <v-data-table
                :headers="[
                    {
                        text: '考区代码',
                        value: 'areaCode',
                        width: '100px',
                    },
                    {
                        text: '考区名称',
                        value: 'name',
                        width: '100px',
                    },
                    {
                        text: '考点数量',
                        value: 'siteCount',
                        width: '100px',
                    },
                    {
                        text: '上报进度',
                        value: 'readedCount',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :items="equipmentReports"
                :items-per-page="5"
                no-data-text="暂无数据"
                loading-text="Loading... Please wait"
            >
                <template v-slot:item.readedCount="{ item }">
                    <replyProcessLinear
                        :value="Percentage(item.readedCount, item.totalCount)"
                    ></replyProcessLinear>
                </template>
                <template v-slot:item.options="{ item }">
                    <v-btn
                        @click="ViewDetail(item)"
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                    >
                        详情
                    </v-btn>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import replyProcessLinear from '@/components/replyProcessLinear/replyProcessLinear';

export default
@Component({ components: { replyProcessLinear } })
class ExamDataStatistics extends Vue {
    static name = window.$views.ExamCsp.ExaminationReport.Index;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    loader;
    @Inject()
    navigation;

    equipmentReports = [];

    progressProps = {
        size: 300,
        rotate: 90,
        width: 25,
        color: 'primary',
        class: 'mx-auto',
    };

    chartSettings = {
        axisTick: {
            show: false,
        },
        dataType: {
            占比: 'percent',
        },
        seriesMap: {
            占比: {
                min: 0,
                max: 1,
                itemStyle: {
                    color: '#439BFB',
                    shadowBlur: 0.5,
                },
                axisLine: {
                    lineStyle: {
                        color: [
                            [0.29, '#439BFB'],
                            [1, '#439BFB'],
                            [1, '#439BFB'],
                        ],
                        width: 10,
                        shadowColor: '#fff',
                        shadowBlur: 10,
                    },
                },
                axisLabel: {
                    textStyle: {
                        fontWeight: 'bolder',
                        color: '#000',
                    },
                },
                axisTick: {
                    show: false,
                },
            },
        },
    };
    registerRate = 0.0;
    ReadedRate = 0.0;
    MonitoringRate = 0.0;
    chartData = {
        columns: ['type', 'value'],
        rows: [{ type: '占比', value: this.registerRate }],
    };
    ReadedchartData = {
        columns: ['type', 'value'],
        rows: [{ type: '占比', value: this.MonitoringRate }],
    };
    MonitoringchartData = {
        columns: ['type', 'value'],
        rows: [{ type: '占比', value: this.ReadedRate }],
    };

    mounted() {
        this.GetExaminationReport();
        this.GetExaminationAreaReport();
    }

    GetExaminationAreaReport() {
        let roomType = localStorage.getItem('roomType');
        this.loading = true;
        this.repository.ExaminationReport.GetExaminationAreaReport(
             this.context.examId,
            roomType
        ).then((res) => {
            this.equipmentReports = res.data;
            this.loading = false;
        });
    }

    GetExaminationReport() {
        let that = this;
        let roomType = localStorage.getItem('roomType');
        this.repository.ExaminationReport.GetExaminationReport(this.context.examId,roomType).then(
            (res) => {
                this.registerRate =
                    res.data.totalCount > 0
                        ? Math.floor(
                              (res.data.roomRegisterCount /
                                  res.data.totalCount) *
                                  100
                          )
                        : 0;
                this.ReadedRate =
                    res.data.totalCount > 0
                        ? Math.floor(
                              (res.data.roomReadCount / res.data.totalCount) *
                                  100
                          )
                        : 0;
                this.MonitoringRate =
                    res.data.totalCount > 0
                        ? Math.floor(
                              (res.data.roomMonitorCount /
                                  res.data.totalCount) *
                                  100
                          )
                        : 0;
                that.chartData = {
                    columns: ['type', 'value'],
                    rows: [{ type: '占比', value: this.regsiterRate }],
                };
                that.ReadedchartData = {
                    columns: ['type', 'value'],
                    rows: [{ type: '占比', value: this.ReadedRate }],
                };
                that.MonitoringchartData = {
                    columns: ['type', 'value'],
                    rows: [{ type: '占比', value: this.MonitoringRate }],
                };
            }
        );
    }

    ViewDetail(item) {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.EquipmentSite,
            {
                examId: this.context.examId,
                districtCode: item.areaCode,
                districtName: item.name,
            }
        );
    }

    ViewReaded() {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.EquipmentDistrict,
            {
                examId: this.context.examId
            }
        );
    }

    ViewRegsiter() {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.RegsiterDistrict,
            {
                examId: this.context.examId
            }
        );
    }

    ViewException(type) {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.RegsiterDistrictException,
            {
                examId: this.context.examId,
                type:type
            }
        );

    }

    ViewMonitor() {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.MonitorDistrict,
            {
                examId: this.context.examId
            }
        );
    }

    Percentage(num, total) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Math.floor((num / total) * 10000) / 100.0;
    }
}
</script>

<style scoped>
.statistics {
    width: 400px;
    /*height: 410px;*/
    /*position: relative;*/
}

.statistics-text {
    position: absolute;
    bottom: 60px;
    left: 160px;
    font-size: 30px;
}

.statistics-table {
    padding: 0 120px;
}
</style>
