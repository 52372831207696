import sender from '../request';
export default class ExaminationDistrictRepository {

    GetExaminationDistrict(data) {
        return sender.get("/ExaminationArea/GetExaminationAreas",data)
    }

    AddOrUpdate(data) {
        return sender.postWithUnCatch("/ExaminationArea/AddOrUpdate", data)
    }

    DelExaminationDistrict(id, roomType) {
        return sender.get("/ExaminationArea/Delete?id=" + id + "&roomType=" + roomType, 200000)
    }

    Login(data) {
        return sender.postWithUnCatch("/ExaminationArea/Login", data)
    }
    SetPassword(data) {
        return sender.postWithUnCatch("/ExaminationArea/SetPassword", data)
    }

    GetExaminationAreaTree(examId,roomType) {
        return sender.getWithUnCatch("/ExaminationArea/GetExaminationAreaTree?roomType=" + roomType+"&&examId=" + examId)
    }
    GetExistExaminationArea(areaCode,examId) {
        return sender.getWithUnCatch("/ExaminationArea/GetExistExaminationArea?areaCode=" + areaCode+"&&examId=" + examId)
    }

    GetRoomTreeInfo(id, roomType) {
        return sender.getWithUnCatch("/ExaminationArea/GetRoomTreeInfo?id=" + id + "&roomType=" + roomType);
    }

    GetEquipmentTreeInfo(id) {
        return sender.getWithUnCatch("/ExaminationArea/GetEquipmentTreeInfo?id=" + id);
    }
    GetSiteTreeInfo(id, roomType) {
        return sender.getWithUnCatch("/ExaminationArea/GetSiteTreeInfo?id=" + id + "&roomType=" + roomType);
    }
    /** 
     * 重置考区面
    */
    ResetAreaPwd(data) {
        return sender.postWithUnCatch("/ExaminationArea/ResetAreaPwd", data)
    }
}
