import Enum from '@/domain/enums/Enum';
import EnumItem from '@/domain/enums/EnumItem';

export default class UserType extends Enum {

    _管理员 = new EnumItem('管理员', 0);
    _组考机构管理员 = new EnumItem('组考机构管理员', 1);
    _考区管理员 = new EnumItem('考区管理员', 2);
    _考点管理员 = new EnumItem('考点管理员', 3);

    get 管理员() {
        return this._管理员;
    }

    get 组考机构管理员() {
        return this._组考机构管理员;
    }

    get 考区管理员() {
        return this._考区管理员;
    }
    get 考点管理员() {
        return this._考点管理员;
    }
}
