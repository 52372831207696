import Vue from 'vue'

import '@/styles/index.scss' // global css

import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import StartUp from "./infrastructures/startup";
import {
    Tree,
    Empty,
    Button,
    Icon,
    Card,
    Table,
    TableColumn,
    Pagination,
    DatePicker,
    TimeSelect,
    TimePicker,
    Tag,
    Loading,
    Dialog,
    Form,
    FormItem,
    Input,
    Switch,
    Col,
    Select,
    Option,
    MessageBox,
    Message,
    Radio
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
StartUp.Initialize();
Vue.use(Tree);
Vue.use(Icon);
Vue.use(Table);
Vue.use(Button);
Vue.use(Card);
Vue.use(Tag);
Vue.use(Empty);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Switch);
Vue.use(Col);
Vue.use(Radio);

// Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm2 = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.use(Input, { size: 'small' });
Vue.use(Button, { size: 'small' });
new Vue({
    router,
    vuetify,
    Tree,
    render: h => h(App)
}).$mount('#app');