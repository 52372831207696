<template>
    <monitor-view
        class="full"
        :page-handler="GetOnlineEquipmentCount"
        :page-data-handler="GetOnlineEquipments"
    >
        <template #options>
            <v-btn
                color="white"
                class="mr-2"
                outlined
                @click="NavigateListPage"
            >
                返回
            </v-btn>
        </template>
    </monitor-view>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import MonitorView from '@/components/MonitorView/Extend';
import Storage from '@/domain/storage';
import keys from '@/domain/keys';

export default
@Component({ components: { MonitorView } })
class ExaminationRoomMonitorViewPageComponent extends Vue {
    static name = window.$views.Monitor.ExaminationRoomMonitor.Index;

    @Inject() repository;
    @Inject() notifier;
    @Inject() navigation;

    siteList = {};

    get identifier() {
        return this.context.identifier;
    }

    get code() {
        return this.context.code;
    }

    get name() {
        return this.context.name;
    }

    get examId(){
        return this.context.examId;
    }

    get areacode(){
        return this.context.areacode;
    }

    async GetOnlineEquipmentCount() {
        let roomType = localStorage.getItem('roomType');
        let response =
            await this.repository.ExaminationRoom.GetOnlineEquipmentCount({
                groupNo: this.identifier,
                roomType: roomType,
                examId: this.examId
            });
        return response.data;
    }

    async GetOnlineEquipments(page) {
        let roomType = localStorage.getItem('roomType');
        this.monitors = [];
        let response =
            await this.repository.ExaminationRoom.GetOnlineMonitorEquipments({
                groupNo: this.identifier,
                pageSize: page.size,
                page: page.cur,
                roomType: roomType,
                examId: this.examId
            });
        return response.data;
    }
    created() {
        if (
            Storage.Get(keys.REGISTRY_INFO) != undefined &&
            Storage.Get(keys.REGISTRY_INFO) != null
        ) {
            this.siteList.type = JSON.parse(
                Storage.Get(keys.REGISTRY_INFO)
            ).type;
        }
    }

    NavigateListPage() {
        console.log(this.siteList.type);
        if (this.siteList.type == 1) {
            this.navigation.redirect(this.$views.ExamCsp.ExaminationRoom.List, {
                areacode:this.areacode,
                code: this.code,
                name: this.name,
                examId: this.examId,
                type: this.context.type,
            });
        } else {
            history.go(-1)
            // this.navigation.redirect(
            //     this.$views.ExaminationAdmin.ExaminationRoom.List,
            //     {
            //         code: this.code,
            //         name: this.name,
            //     }
            // );
        }
    }
}
</script>
