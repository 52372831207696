<template>
    <div class="relative">
        <div style="position: relative;height: 50px;">
            <v-row class="d-flex mb-n4 mt-3" no-gutters>
            <v-btn
                color="primary"
                style="margin-left: 20px;"
                @click="AddClick"
                v-show="user.JurisdictionCheck"
            >
                新增考区
            </v-btn>

            <v-btn
                color="primary"
                style="margin-left: 20px;"
                @click="importDialog = true"
                v-show="user.JurisdictionCheck"
            >
                导入考区
            </v-btn>

            <v-btn color="primary" style="margin-left: 20px;" @click="allLookMonitor">
                查看监控
            </v-btn>
            <a
                v-if="isPublishUrlShow"
                class="blue--text"
                :href="publishUrl"
                target="_blank"
                style="position: absolute; right: 95px; top: 0px;">
                <v-btn class="primary-btn" color="success">
                    <v-icon size="20">mdi-link-variant</v-icon>
                    <span>考区管理员登录地址</span>
                </v-btn>
            </a>
            <v-btn style="position: absolute; right: 10px; top: 0px;"  color="primary" @click="allLookMonitor" :to="$to($views.ExamCsp.Exam.List)">
                返回
            </v-btn>
        </v-row>
        </div>
        <div class="px-4 pt-n4">
            <v-data-table
                :headers="[
                    {
                        text: '考区代码',
                        value: 'areaCode',
                        width: '100px',
                    },
                    {
                        text: '考区名称',
                        value: 'areaName',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :items-per-page="10"
                :items="examinationdistricts"
                no-data-text="暂无数据"
            >
                <template v-slot:item.options="{ item }">
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="lookMonitor(item)"
                    >
                        查看监控
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        :to="
                            $to($views.ExamCsp.ExaminationSite.List, {
                                areacode: item.areaCode,
                                type: 1,
                                areaname: item.areaName,
                                examId:item.examId
                            })
                        "
                    >
                        考点管理
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        v-show="user.JurisdictionCheck"
                        class="mr-2"
                        @click="resetAreaPwd(item)"
                    >
                        重置密码
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        v-show="user.JurisdictionCheck"
                        class="mr-2"
                        @click="UpdateClick(item)"
                    >
                        修改
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        v-show="user.JurisdictionCheck"
                        outlined
                        @click="deleteClick(item.id)"
                    >
                        删除
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <v-dialog max-width="600" persistent v-model="accountSettingsDialog">
            <v-card>
                <v-card-title class="headline primary white--text">
                    重置密码
                </v-card-title>
                <v-row no-gutters class="mt-4 mx-6">
                    <v-col class="mt-2 text-right" cols="3">
                        <label class="mt-2">原密码：</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="setpassword.Password"
                            type="password"
                            placeholder="请输入原密码"
                            dense
                            required
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-4 mx-6">
                    <v-col class="mt-2 text-right" cols="3">
                        <label class="mt-2">新密码：</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="setpassword.NewPassword"
                            type="password"
                            placeholder="请输入新密码"
                            dense
                            required
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-4 mx-6">
                    <v-col class="mt-2 text-right" cols="3">
                        <label class="mt-2">确认新密码：</label>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="setpassword.ReNewPassword"
                            type="password"
                            placeholder="请再次输入新密码"
                            dense
                            required
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-divider></v-divider>

                <div class="text-center py-2">
                    <v-btn e2e-save-city color="primary" @click="saveAccount">
                        保存
                    </v-btn>
                    <v-btn
                        @click="accountSettingsDialog = false"
                        color="primary"
                        class="ml-2"
                        outlined
                    >
                        取消
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500" persistent v-model="addDistrictDialog">
            <v-card>
                <v-card-title class="headline primary white--text">
                    {{ this.filedname }}
                </v-card-title>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考区代码：</label>
                    <v-text-field
                        v-model="addDistrictInfo.AreaCode"
                        placeholder="请输入考区代码"
                        dense
                        required
                        outlined
                    />
                </div>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考区名称：</label>
                    <v-text-field
                        v-model="addDistrictInfo.AreaName"
                        placeholder="请输入考区名称"
                        dense
                        required
                        outlined
                    />
                </div>
                <v-divider></v-divider>

                <div class="text-center py-2">
                    <v-btn e2e-save-city @click="save()" color="primary">
                        保存
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="ml-2"
                        outlined
                        @click="addDistrictDialog = false"
                    >
                        取消
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="900"
            style="max-height: 500px; top: 20px"
            persistent
            v-model="errorDialog"
        >
            <v-card>
                <v-card-title>导入异常数据列表 &nbsp;&nbsp;
                    <span style="font-weight: normal;font-size: 17px;">成功: {{ reqData.length - errorInfos.length }},失败: {{ errorInfos.length }}</span>
                </v-card-title>
                <v-data-table
                    :headers="[
                        {
                            text: '考区代码',
                            value: 'areaCode',
                            width: '100px',
                        },
                        {
                            text: '考区名称',
                            value: 'areaName',
                            width: '100px',
                        },
                        {
                            text: '考点代码',
                            value: 'siteCode',
                            width: '100px',
                        },
                        {
                            text: '考点名称',
                            value: 'siteName',
                            width: '100px',
                        },
                        {
                            text: '考场代码',
                            value: 'roomCode',
                            width: '100px',
                        },
                        {
                            text: '考场名称',
                            value: 'roomName',
                            width: '100px',
                        },
                        {
                            text: '考场类型',
                            value: 'roomType',
                            width: '100px',
                        },
                        {
                            text: '错误信息',
                            value: 'errMsg',
                            width: '100px',
                        },
                    ]"
                    :items="errorInfos"
                    no-data-text="暂无数据"
                    :items-per-page="20"
                    hide-default-footer
                >
                    <template v-slot:item.errMsg="{ item }">
                        <span class="red--text">{{ item.errMsg }}</span>
                    </template>
                </v-data-table>
                <div class="text-center pa-2">
                    <v-btn color="primary" @click="errorDialog = false">
                        关闭
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            max-width="600"
            persistent
            v-model="importDialog"
        >
            <v-card>
                <v-card-title>导入考区</v-card-title>
                <div
                    class="text-sm-left caption red lighten-5 pa-2 ml-5 mr-5"
                    v-show="user.JurisdictionCheck"
                >
                    温馨提示：<br>1、下载考点考场信息导入模版
                    <span class="text-sm-left caption lighten-5">
                        <a @click="downloadTemplate">【下载模版】</a>
                    </span><br>
                    2、导入考点考场信息
                    </div>
                   <div class="d-flex" style="padding: 20px 20px 20px 0;">
                        <div
                            style="margin-left: 20px;"
                            cols="auto"
                            class="mt-2"
                            v-show="user.JurisdictionCheck"
                        >
                            <label>导入数据</label>
                        </div>
                        <div
                            class="input-width"
                            v-show="user.JurisdictionCheck"
                        >
                            <v-file-input
                                :rules="[(v) => !!v || '请选择要导入Excel']"
                                outlined
                                dense
                                label="请选择Excel模板"
                                v-model="file"
                            />
                        </div>
                        <div class="ml-2" v-show="user.JurisdictionCheck">
                            <v-btn color="primary" @click="upload">导入</v-btn>
                        </div>
                    </div>
                <div class="text-center pa-2 pb-5">
                    <v-btn color="primary" @click="importDialog = false">
                        关闭
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import ExcelGenerator from '@/infrastructures/excel/Generator';
import Reader from '@/infrastructures/excel/Reader';
import XLSX from 'xlsx/xlsx';
export default {
    name: window.$views.ExamCsp.ExaminationDistrict.List,
    inject: ['repository', 'navigation', 'notifier', 'user','enums'],
    data() {
        return {
            importDialog: false,
            reqData: [],
            errorInfos: [],
            errorDialog: false,
            file: null,
            headers: [
                '考区代码',
                '考区名称',
                '考点代码',
                '考点名称',
                '考场代码',
                '考场名称',
                '考场类型'
            ],
            publishUrl:'',
            examId:'',
            isPublishUrlShow:true,
            filedname: '新增考区',
            areaname: '',
            accountSettingsDialog: false,
            addDistrictDialog: false,
            addDistrictInfo: { Id: '', AreaName: '', AreaCode: '', ExamId: '' },
            setpassword: {
                Id: '',
                Password: '',
                NewPassword: '',
                ReNewPassword: '',
            },
            examinationdistricts: [],
        };
    },
    mounted() {
        this.examId = this.context.examId;
        let loginInfo = this.user.GetInfo;
        if(loginInfo.type != this.enums.UserType.组考机构管理员.value) this.isPublishUrlShow = false;
        else {
            this.publishUrl = this.navigation.resolveUrl(
                this.$views.Login,
                {
                    exam: this.examId,
                    userType:this.enums.UserType.考区管理员.value
                }
            );
        }
        this.getExaminationdistricts();
    },
    methods: {
        downloadTemplate(){
            let generator = new ExcelGenerator();
            let res = [
                {
                    考区代码: '14401',
                    考区名称: '广州市',
                    考点代码: '14',
                    考点名称: '广州市技师学院(黄石校区)',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场内'
                },
                {
                    考区代码: '14401',
                    考区名称: '广州市',
                    考点代码: '14',
                    考点名称: '广州市技师学院(黄石校区)',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场内'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '01',
                    考点名称: '河源技师学院',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场内'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '01',
                    考点名称: '河源技师学院',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '02',
                    考点名称: '河源市卫生学校',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14402',
                    考区名称: '河源市',
                    考点代码: '02',
                    考点名称: '河源市卫生学校',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14403',
                    考区名称: '惠州市',
                    考点代码: '01',
                    考点名称: '惠州经济职业技术学院',
                    考场代码: '001',
                    考场名称: '第1考场',
                    考场类型: '场外'
                },
                {
                    考区代码: '14403',
                    考区名称: '惠州市',
                    考点代码: '01',
                    考点名称: '惠州经济职业技术学院',
                    考场代码: '002',
                    考场名称: '第2考场',
                    考场类型: '场外'
                },
            ];
            let data = this.formatJson(this.headers, res);
            console.log(data);
            generator
                .AddHeaders(this.headers)
                .AddRows(data)
                .Save('考点考场导入信息模板.xlsx');
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) => filterVal.map((j) => v[j]));
        },
        async loadingData() {
            let reader = new Reader();
            //读取excel处理
            let workbook = await reader.ReadWorkbook(this.file);
            console.log(workbook);
            console.log(this.file);
            let examinationSitesAndRooms =
                await this.GetExaminationSitesAndRooms(workbook);
            return examinationSitesAndRooms;
        },
        async GetExaminationSitesAndRooms(workbook) {
            let examinationSitesAndRooms = new Array();
            let sheetNames = workbook.SheetNames; // 工作表名称集合
            sheetNames.forEach((name) => {
                let worksheet = workbook.Sheets[name]; // 只能通过工作表名称来获取指定工作表
                let excelJson = XLSX.utils.sheet_to_json(worksheet);
                for (let excelmodel in excelJson) {
                    let examinationSitesAndRoom = new Object();
                    examinationSitesAndRoom.AreaCode =
                        excelJson[excelmodel].考区代码.toString();
                    examinationSitesAndRoom.SiteCode =
                        excelJson[excelmodel].考点代码.toString();
                    examinationSitesAndRoom.SiteName =
                        excelJson[excelmodel].考点名称.toString();
                    examinationSitesAndRoom.RoomCode =
                        excelJson[excelmodel].考场代码.toString();
                    examinationSitesAndRoom.RoomName =
                        excelJson[excelmodel].考场名称.toString();
                    examinationSitesAndRoom.AreaName =
                        excelJson[excelmodel].考区名称.toString();
                    examinationSitesAndRoom.RoomType =
                        excelJson[excelmodel].考场类型;
                    examinationSitesAndRooms.push(examinationSitesAndRoom);
                }
            });
            return examinationSitesAndRooms;
        },
        async upload() {
            if (this.file == undefined) {
                this.notifier.warn('请选择上传文件');
                return;
            }
            let fillExtension = this.file.name
                .substring(this.file.name.lastIndexOf('.') + 1)
                .toLowerCase();

            if (fillExtension.indexOf('xls') == -1) {
                this.notifier.warn('导入失败，请检查模板是否正确');
                return;
            }
            let data = await this.loadingData();
            if (data[0].AreaCode == undefined) {
                this.notifier.warn('导入失败，请检查模板是否正确');
                return;
            }
            if (data == '') {
                this.notifier.warn('Excel无导入数据，请填写！');
                return;
            }
            this.reqData = data
            this.repository.ExaminationSite.ImportExamSiteAndRoom(data,this.examId)
                .then((res) => {
                    this.file = undefined;
                    if (res.data.length > 0 && res.data.length < data.length) {
                        this.errorDialog = true;
                        this.errorInfos = res.data;
                        this.notifier.warn('部分导入成功');
                        this.getExaminationdistricts();
                    } else if (res.data.length == data.length) {
                        this.errorInfos = res.data;
                        this.errorDialog = true;
                        this.notifier.error('导入失败');
                    } else {
                        this.notifier.success('导入成功');
                        this.getExaminationdistricts();
                    }
                })
                .catch((res) => {
                    console.log(res);
                    this.notifier.error('导入失败请检查格式是否正确');
                });
        },
        // 市级：查看监控
        lookMonitor(item) {
            console.log(item);
            this.navigation.redirect(
                this.$views.Monitor.ExaminationCityMonitor.Index,
                {
                    from: 'city',
                    examId:item.examId,
                    code: item.areaCode,
                    areacode: item.areaCode,
                    areaname: item.areaName,
                    type: 1,
                } // 增加 from:'city'参数（视频监控可以切换当前城市内所有考点视频监控）
            );
        },
        // 省级：查看监控
        allLookMonitor() {
            this.navigation.redirect(
                this.$views.Monitor.ExaminationCityMonitor.Index,
                { from: 'city', areacode: '',examId:this.examId, type: 1, leftMenu: true } // 增加 from:'city'参数（视频监控可以切换当前城市内所有考点视频监控）
            );
        },
        resetAreaPwd(item) {

            var data={Id:item.id};
            this.repository.ExaminationDistrict.ResetAreaPwd(data).then(
                () => {
                    this.notifier.success('密码重置成功');
                }
            );
            // this.accountSettingsDialog = true;
            // this.areaname = item.areaName;
            // this.setpassword.Id = item.id;
            // this.setpassword.Password = '';
            // this.setpassword.NewPassword = '';
            // this.setpassword.ReNewPassword = '';
            // this.setpassword.Type = this.enums.UserType.考区管理员.value;
        },
        saveAccount() {
            this.SetPassWord();
        },
        getExaminationdistricts() {
            var data = {examId:this.examId};
            this.repository.ExaminationDistrict.GetExaminationDistrict(data).then(
                (res) => {
                    this.examinationdistricts = res.data;
                }
            );
        },

        save() {
            if (this.isAdd) {
                this.AddExaminationDistrict();
            } else {
                this.UpdateExaminationDistrict();
            }
        },
        AddClick() {
            this.addDistrictDialog = true;
            this.isAdd = true;
            this.addDistrictInfo = {};
        },
        UpdateClick(item) {
            this.filedname = '修改考区';
            this.settingData(item);
            this.addDistrictDialog = true;
            this.isAdd = false;
        },
        settingData(item) {
            this.addDistrictInfo.ExamId = this.examId
            this.addDistrictInfo.AreaCode = item.areaCode;
            this.addDistrictInfo.AreaName = item.areaName;
            this.addDistrictInfo.Password = item.passWord;
            this.addDistrictInfo.id = item.id;
        },
        AddExaminationDistrict() {
            if (this.addDistrictInfo.AreaName == null) {
                this.notifier.warn(`请输入考区名称`);
                return;
            }
            if (this.addDistrictInfo.AreaCode == null) {
                this.notifier.warn(`请输入考区代码`);
                return;
            }
            this.addDistrictInfo.ExamId = this.examId;
            this.repository.ExaminationDistrict.AddOrUpdate(
                this.addDistrictInfo
            ).then(() => {
                this.addDistrictDialog = false;
                this.notifier.success('保存成功');
                this.getExaminationdistricts();
            });
        },
        SetPassWord() {
            if (this.setpassword.Password == '') {
                this.notifier.warn(`请输入原密码`);
                return;
            }
            if (this.setpassword.NewPassword == '') {
                this.notifier.warn(`请输入新密码`);
                return;
            }
            if (this.setpassword.ReNewPassword == '') {
                this.notifier.warn(`请确认新密码`);
                return;
            }
            if (
                this.setpassword.ReNewPassword != this.setpassword.NewPassword
            ) {
                this.notifier.warn(`确认新密码和新密码不一致,请重新输入`);
                return;
            }
            this.repository.ExaminationDistrict.SetPassword(
                this.setpassword
            ).then(() => {
                this.notifier.success('密码重置成功');
                this.accountSettingsDialog = false;
            });
        },
        UpdateExaminationDistrict() {
            this.repository.ExaminationDistrict.AddOrUpdate(
                this.addDistrictInfo
            ).then(() => {
                this.notifier.success('修改成功');
                this.addDistrictDialog = false;
                this.accountSettingsDialog = false;
                this.getExaminationdistricts();
            });
        },
        deleteClick(id) {
            let roomType = localStorage.getItem('roomType');
            this.$confirm('提示', `确定要删除吗？`).then(() => {
                this.repository.ExaminationDistrict.DelExaminationDistrict(
                    id,
                    roomType
                ).then(() => {
                    this.notifier.success('删除成功');
                    this.getExaminationdistricts();
                });
            });
        },
    },
};
</script>
