<template>
    <div class="relative">

        <el-button class="ma-4" type="primary" v-show="user.JurisdictionCheck" icon="el-icon-circle-plus-outline" @click="AddClick">新增考试</el-button>

        <div class="tabList">
            <div
                class="tab_item font-14 border-left"
                 v-for="(item, index) in tabList"
                 :key="index"
                 :class="{active: currentTab === item.value,}"
                 @click="()=>{switchTab(item.value)}"
            >
               <span>{{ item.label }}（{{getCount(index)}}）</span>
            </div>
        </div>
        <div class="px-4 pt-n4" style="overflow: scroll;">
            <div
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                class="table-wrapper">
                <el-card
                v-for="(item,index) in Exams"
                :key="index" class="box-card">
                <div slot="header" class="top-header">
                    <div  v-if="item.examStatus === 1" class="status1">
                        <div><i class="el-icon-warning-outline"/></div>
                        <div class="font-14">未发布</div>
                    </div>
                    <div v-if="item.examStatus === 2" class="status2">
                        <div ><i class="el-icon-circle-check" /></div>
                        <div class="font-14">已发布</div>
                    </div>
                    <div v-if="item.examStatus === 3" class="status3">
                        <div ><i class="el-icon-circle-close" /></div>
                        <div class="font-14">已结束</div>
                    </div>
                    <div class="clearfix" style="width:100%;">
                    <div class="left" style="float: left; margin-left: 20px;">
                        <b class="exam-title">{{ item.examName }}
                              <el-tag v-if="item.topFlg" size="mini" type="success" style="position: relative;top:-2px;">已置顶</el-tag>
                        </b>

                        <div class="font-14 mt-3">
                            <span class="mr-5">
                                <i class="el-icon-collection-tag" /> 考试代码：<b>{{ item.examCode }}</b>
                            </span>
                            <span class="mr-3">
                                <i class="el-icon-stopwatch" /> 定时监控：
                                <el-tag v-if="item.isOpenTask" size="mini" type="success">运行中</el-tag>
                                <el-tag v-else size="mini" type="warning">未启用</el-tag>

                            </span>
                            <span v-if="item.isOpenTask">
                                <i class="el-icon-video-camera" /> 监控状态：
                                <el-tag v-if="item.isOpenMonitor" size="mini" type="success">开启监控</el-tag>
                                <el-tag v-else size="mini" type="warning">停止监控</el-tag>
                            </span>
                        </div>
                        <div class="font-14 mt-3">
                            <i class="el-icon-time"/> 考试时间：<span>{{ item.examStartTime }}</span> -  <span>{{ item.examEndTime }}</span>
                        </div>
                        <div class="font-14">
                            <!-- <span v-if="item.openMonitor">监控时间：{{ item.monitorTypeDesc }} |</span>
                            <span v-if="item.monitorType===1">监控时间：{{ item.monitorStartDate }} {{ item.monitorStartTime }}  - {{ item.monitorEndDate }} {{ item.monitorEndTime }}</span> -->
                        </div>
                    </div>
                    <div class="right"  style="float: right;" >
                        <v-btn
                            color="primary"
                            middle
                            style="position: relative;top: 35px;"
                            @click="goArea(item) "
                        >
                            <i class="el-icon-office-building" style="vertical-align: sub;"/>
                            <span >考区管理</span>
                        </v-btn>
                    </div>
                </div>
                </div>
                <div class="text item bottom-wrapper">
                    <div class="left">
                        <i class="el-icon-date"/> 创建时间：{{ item.createTime }}
                    </div>
                    <div class="right">
                        <v-btn
                            color="primary"
                            small
                            outlined
                            v-show="user.JurisdictionCheck && item.topFlg === false"
                            class="mr-2"
                            @click="TopExam(item)"
                        >
                        <i class="el-icon-top" />  置顶考试
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            outlined
                            v-show="user.JurisdictionCheck && item.topFlg === true"
                            class="mr-2"
                            @click="CancelToppingExam(item)"
                        >
                        <i class="el-icon-top" />  取消置顶
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            outlined
                            v-show="user.JurisdictionCheck"
                            class="mr-2"
                            @click="UpdateClick(item)"
                        >
                        <i class="el-icon-s-tools" />  修改考试
                        </v-btn>
                        <v-btn
                            color="primary"
                            small
                            outlined
                            v-show="user.JurisdictionCheck"
                            class="mr-2"
                            @click="openMonitor(item)"
                        >
                        <i class="el-icon-s-tools" />  监控设置
                        </v-btn>
                        <v-btn class="mr-2"  v-if="item.examStatus === 1" outlined color="green" small @click="publish(item)"><i class="el-icon-s-promotion" /> 发布考试</v-btn>
                        <v-btn  class="mr-2" v-if="item.examStatus === 2" outlined  color="error"  small @click="unPublish(item)"><i class="el-icon-circle-close" />结束考试</v-btn>
                        <v-btn
                            color="error"
                            small
                            v-show="user.JurisdictionCheck"
                            v-if="item.examStatus === 1"
                            outlined
                            @click="deleteClick(item.id)"
                        >
                        <i class="el-icon-remove-outline" />  删除
                        </v-btn>
                    </div>
                </div>
            </el-card>
            <el-empty v-if="Exams.length==0" description="暂无数据"></el-empty>

          </div>
        </div>

        <el-dialog
            :title="typeText + '考试'"
            :close-on-click-modal="false"
            :visible.sync="addExamDialog"
            :before-close="handleClose"
            width="650px">
            <el-form ref="form" :model="addExamInfo" label-width="120px">

                <el-form-item label="考试名称" prop="examName">
                    <el-input v-model="addExamInfo.examName" autocomplete="off" />
                </el-form-item>
                <el-form-item label="考试时间">
                    <el-col :span="11">
                        <el-form-item prop="examStartTime">
                            <el-date-picker
                                clearable
                                type="datetime"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期和时间"
                                v-model="addExamInfo.examStartTime"
                                style="width: 100%;">
                            </el-date-picker>
                        </el-form-item>

                    </el-col>
                    <el-col class="line" :span="2">&nbsp;&nbsp;-</el-col>
                    <el-col :span="11">
                        <el-form-item prop="examEndTime">
                            <el-date-picker
                                clearable
                                type="datetime"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择日期和时间"
                                v-model="addExamInfo.examEndTime"
                                style="width: 100%;">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea"  v-model="addExamInfo.remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="handleSave()">保 存</el-button>
                <el-button size="small" plain @click="addExamDialog=false">取 消</el-button>
            </span>
        </el-dialog>


    <el-dialog
        title="监控设置"
        :close-on-click-modal="false"
        :visible.sync="monitorDialog"
        width="600px"
    >
      <el-form ref="ruleForm" :model="addExamInfo" label-width="150px" style="margin-right:25px;">
        <el-form-item label="定时监控" prop="isOpenTask">
          <el-switch v-model="addExamInfo.isOpenTask"></el-switch>
        </el-form-item>
        <div v-if="addExamInfo.isOpenTask">
            <el-form-item  label="监控状态" prop="isOpenMonitor">
            <el-radio v-model="addExamInfo.isOpenMonitor" :label="true">开启监控</el-radio>
            <el-radio v-model="addExamInfo.isOpenMonitor" :label="false">停止监控</el-radio>
            </el-form-item>
            <el-form-item v-if="addExamInfo.isOpenMonitor" label="运行时段">
                <el-radio v-model="addExamInfo.monitorType" :label="0">所有时段</el-radio>
                <el-radio v-model="addExamInfo.monitorType" :label="1">指定时段</el-radio>
            </el-form-item>
            <el-form-item v-if="addExamInfo.isOpenMonitor && addExamInfo.monitorType === 1" label="运行日期">
                <el-col :span="11">
                    <el-date-picker
                    format='yyyy-MM-dd'
                    value-format="yyyy-MM-dd"
                    size="small" type="date" placeholder="选择日期" v-model="addExamInfo.monitorStartDate" style="width: 100%;"></el-date-picker>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                    <el-date-picker
                    format='yyyy-MM-dd'
                    value-format="yyyy-MM-dd"
                    size="small" type="date" placeholder="选择日期" v-model="addExamInfo.monitorEndDate" style="width: 100%;"></el-date-picker>
                </el-col>
            </el-form-item>
            <el-form-item  v-if="addExamInfo.isOpenMonitor && addExamInfo.monitorType === 1"  label="运行时间">
                <el-col :span="11">
                    <el-time-picker
                        size="small"
                        v-model="addExamInfo.monitorStartTime"
                        format="HH:mm"
                        value-format="HH:mm"
                        style="width: 100%;"
                        placeholder="开始时间">
                    </el-time-picker>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                    <el-time-picker
                        size="small"
                        v-model="addExamInfo.monitorEndTime"
                        format="HH:mm"
                        value-format="HH:mm"
                        style="width: 100%;"
                        placeholder="结束时间">
                    </el-time-picker>
                </el-col>
            </el-form-item>
        </div>
        <el-form-item>
          <el-col :span="12" style="margin-top:20px;">
            <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
            <el-button  size="small"  @click="closeDialog">取 消</el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </el-dialog>
    </div>
</template>
<script>

export default {
    name: window.$views.ExamCsp.Exam.List,
    inject: ['repository', 'navigation', 'notifier', 'user','enums'],
    data() {
        return {
            typeText:'新增',
            monitorDialog: false,
            pendingQty:0,
            publishedQty:0,
            successedQty:0,
            sumQty: 0,
            loading: false,
            currentTab: '0',
            examStatus: '0',
            ruleForm: {
                isOpenMonitor: '',
                isOpenTask: '',
                monitorType: '',
                monitorStartDate: '',
                monitorEndDate: '',
                monitorStartTime: '',
                monitorEndTime: '',
            },
            rules: {
                monitorType: [
                    { required: true, message: '请选择监控类型', trigger: 'blur' },
                ],
            },
            tabList: [
                {label: '已发布', value: '2'},
                {label: '未发布', value: '1'},
                {label: '已结束', value: '3'},
                {label: '全部', value: '0'},
            ],
            monitorTypeList: [
                {label: '所有时段', value: 0},
                {label: '指定时段', value: 1},
            ],
            tableHeaders: [
                {
                    title: '考试代码',
                    prop: 'examCode',
                },
                {
                    title: '考试名称',
                    prop: 'examName',
                },
                {
                    title: '任务状态',
                    prop: 'openTaskDesc',
                },
                {
                    title: '监控状态',
                    prop: 'openMonitorDesc',
                },
                {
                    title: '监控时间',
                    prop: 'monitorTypeDesc',
                },
                {
                    title: '操作',
                    prop: 'operate',
                    columnType: true,
                    width: 300,
                    slotName: 'operate'
                }
            ],
            publishUrl:'',
            examId:'',
            isPublishUrlShow:false,
            Examname: '',
            accountSettingsDialog: false,
            addExamDialog: false,
            addExamInfo: {
                // id: null,
                examStartTime: '',
                examEndTime: '',
                examName: '',
                examCode: '',
                isOpenTask: false,
                isOpenMonitor: false,
                monitorType: '',
                monitorStartDate: '',
                monitorEndDate: '',
                monitorStartTime: '',
                monitorEndTime: '',
                remark: ''
            },
            setpassword: {
                Id: '',
                Password: '',
                NewPassword: '',
                ReNewPassword: '',
            },
            Exams: [],
        };
    },
    mounted() {
        this.getExams();
    },
    methods: {
        goArea(item){
            localStorage.setItem('examName',  item.examName)
            this.$router.push({name:this.$views.ExamCsp.ExaminationDistrict.List, query: { examId:item.id, name: item.examName } })
            // this.$to(this.$views.ExamCsp.ExaminationDistrict.List, {
            //                     examId:item.id,
            //                     name: item.examName
            // })
        },
        handleClose(){
            this.$refs['form'].resetFields()
            this.resetForm()
            this.addExamDialog = false
        },
        resetForm(){
            this.addExamInfo = {
                // id: null,
                examName: '',
                examCode: '',
                isOpenTask: false,
                isOpenMonitor: false,
                monitorType: '',
                monitorStartDate: '',
                monitorEndDate: '',
                monitorStartTime: '',
                monitorEndTime: '',
                remark: ''
            }
        },
        handleSave() {
            this.$refs['form'].validate(valid => {
            if (valid) {
            // const target = this.waitRoomList.find(v => v.id === this.form.waitingRoomId)
            // const waitingRoomCode = target.waitingRoomCode
            const formCopy = { ...this.addExamInfo }
            if (this.typeText === '新增') {
                this.AddExam(formCopy).then(() => {
                    this.addExamDialog = false
                    this.$message.success('操作成功')
                    // this.getList()
                    // this.reset()
                })
            } else {
                this.UpdateExam(formCopy).then(() => {
                // if (res.code === 200) {
                    this.addExamDialog = false
                    this.$message.success('更新成功')
                    this.getList()
                // }
                })
            }
            } else {
                return false
        }
        })
        },
        publish(item) {
            this.$confirm('确认发布？').then(() => {
                this.repository.Exam.UpdExamStatus(
                {id: item.id, examStatus: 2}
            ).then(() => {
                this.notifier.success('发布成功');
                this.getExams();
            });
          })
        },
        TopExam(item){
            this.$confirm('确认置顶当前考试吗？').then(() => {
                this.repository.Exam.ToppingExam(
                {id: item.id}
            ).then(() => {
                this.notifier.success('置顶考试成功');
                this.getExams();
            });
            })
        },
        CancelToppingExam(item) {
            this.$confirm('取消置顶当前考试吗？').then(() => {
                this.repository.Exam.CancelToppingExam(
                {id: item.id}
            ).then(() => {
                this.notifier.success('取消置顶考试成功');
                this.getExams();
            });
            })
        },
        unPublish(item) {
            this.$confirm('确认结束考试吗？').then(() => {
                this.repository.Exam.UpdExamStatus(
                {id: item.id, examStatus: 3}
            ).then(() => {
                this.notifier.success('结束考试成功');
                this.getExams();
            });
          })
        },
        closeDialog() {
            this.monitorDialog = false
        },
        openMonitor(item){
            this.monitorDialog = true
            // this.resetForm()
            this.addExamInfo = {...item}
        },
        submitForm(){
            this.SetMonitorInfo();
        },
        getCount(index){
            if(index ===0) {
                return  this.publishedQty
            }
            if(index ===1) {
                return this.pendingQty

            }
            if(index ===2) {
                return this.successedQty
            }
            if(index ===3) {
                return this.sumQty
            }
        },
        switchTab(param){
            this.currentTab = param
            this.getExams();
        },
        getExams() {
            this.loading = true
            const param = {examStatus: this.currentTab}
            this.repository.Exam.GetExamList(param).then(
                (res) => {
                    this.loading = false
                    this.Exams = res.data.examList;
                    this.publishedQty = res.data.publishedQty
                    this.pendingQty = res.data.pendingQty
                    this.successedQty = res.data.successedQty
                    this.sumQty = res.data.sumQty
                }
            );
        },
        save() {
            if (this.typeText==='新增') {
                this.AddExam();
            } else {
                this.UpdateExam();
            }
        },
        AddClick() {
            this.addExamDialog = true;
            this.isAdd = true;
            // this.addExamInfo = {};
            this.resetForm()
            this.addExamInfo.monitorType = 0;
            this.typeText = '新增'
        },
        UpdateClick(item) {
            this.settingData(item);
            this.addExamDialog = true;
            this.typeText='修改'
        },
        settingData(item) {
            const data = JSON.parse(JSON.stringify(item))
            this.addExamInfo.examCode = data.examCode;
            this.addExamInfo.examName = data.examName;
            this.addExamInfo.remark = data.remark || '';
            this.addExamInfo.examStartTime = data.examStartTime;
            this.addExamInfo.examEndTime = data.examEndTime;
            this.addExamInfo.monitorStartTime = data.monitorStartTime;
            this.addExamInfo.monitorEndTime = data.monitorEndTime;
            this.addExamInfo.monitorStartDate = data.monitorStartDate;
            this.addExamInfo.monitorEndDate = data.monitorEndDate;
            this.addExamInfo.isOpenTask = data.isOpenTask;
            this.addExamInfo.isOpenMonitor = data.isOpenMonitor;
            this.addExamInfo.monitorType = data.monitorType;
            this.addExamInfo.id = data.id;
        },
        AddExam() {

            if (this.addExamInfo.examName == '') {
                this.$message.warning('请输入考试名称')
                // this.notifier.warn(`请输入考试名称`);
                return;
            }
            this.repository.Exam.AddOrUpdate(
                this.addExamInfo
            ).then(() => {
                this.addExamDialog = false;
                this.$message.success('保存成功');
                this.getExams();
            });
        },
        UpdateExam() {
            this.repository.Exam.AddOrUpdate(
                this.addExamInfo
            ).then(() => {
                this.$message.success('修改成功');
                this.addExamDialog = false;
                this.accountSettingsDialog = false;
                this.monitorDialog = false
                this.getExams();
            });
        },

        SetMonitorInfo() {
            if (this.addExamInfo.isOpenMonitor && this.addExamInfo.monitorType ==1) {
               if(this.addExamInfo.monitorStartDate == null || this.addExamInfo.monitorStartDate == null){
                this.$message.warning('运行日期不能为空');
                return;
               }
               if(this.addExamInfo.monitorStartTime == null || this.addExamInfo.monitorEndTime == null){
                this.$message.warning('运行时间不能为空');
                return;
               }
            }
            this.repository.Exam.SetMonitorInfo(
                this.addExamInfo
            ).then(() => {
                this.$message.success('设置成功');
                this.addExamDialog = false;
                this.accountSettingsDialog = false;
                this.monitorDialog = false
                this.getExams();
            });
        },

        deleteClick(id) {
            this.$confirm('提示', `确定要删除吗？`).then(() => {
                this.repository.Exam.DelExam(
                    id
                ).then(() => {
                    this.$message.success('删除成功');
                    this.getExams();
                });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.line {
    padding-left: 10px;
}
.box-card {
    margin-bottom: 20px;
}
.box-card:nth-child(1) {
    margin-top: 5px;
}
.tabList {
    display: flex;
    padding: 5px 20px 25px;
    .tab_item {
        position: relative;
        min-width: 90px;
        margin-right: 20px;
        cursor: pointer;
        text-align: center;
        // margin-top: 20px;
    }
    // .tab_item:nth-child(n>1) {
    //     margin-top: 20px;
    // }
    .tab_item:nth-child(1):before {
        content: "";
        position: absolute;
        left: 0;
        top: 20%;
        bottom: auto;
        right: auto;
        height: 60%;
        width: 4px;
        border-radius: 5px;
        background-color: #198754;
    }
    .tab_item:nth-child(2):before {
        content: "";
        position: absolute;
        left: 0;
        top: 20%;
        bottom: auto;
        right: auto;
        height: 60%;
        width: 4px;
        border-radius: 5px;
        background-color: #DC3545;
    }
    .tab_item:nth-child(3):before {
        content: "";
        position: absolute;
        left: 0;
        top: 20%;
        bottom: auto;
        right: auto;
        height: 60%;
        width: 4px;
        border-radius: 5px;
        background-color: #6C757D;
    }
    .tab_item:nth-child(4):before {
        content: "";
        position: absolute;
        left: 0;
        top: 20%;
        bottom: auto;
        right: auto;
        height: 60%;
        width: 4px;
        border-radius: 5px;
        background-color: #ACE9D9;
    }
    // .tab_item:nth-child(2){
    //     border-left: 2px solid #DC3545;
    // }

    // .tab_item:nth-child(3){
    //     border-left: 2px solid #6C757D;
    // }
    .border-left{
        padding-left: 5px;
    }
    .active {
        color: #0D6EFD;
    }
    .active:before {
        content: "";
        position: absolute;
        left: 5px;
        top: 20%;
        bottom: auto;
        right: auto;
        height: 20px;
        width: 4px;
        border-radius: 5px;
        background-color: #0D6EFD !important;
    }

    .active::after {
        content: '';
        position: absolute;
        left: 10%;
        top: 25px;
        bottom: 0;
        right: auto;
        height: 2px;
        width: 65px;
        border-radius: 5px;
        background-color: #0D6EFD !important;
    }
}
.top-header {
    display: flex;
    .status1 {
        width: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #FAECEB;
        color: #F36464;

    }
    .status2 {
        width: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #E5F8F3;
        color: #198754;
    }
    .status3 {
        width: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #6C757D;
        color: #fff;
    }
}

::v-deep  .el-card__body {
    padding: 10px;
    background-color: #f7f9fd;
    margin-top: -1px;
}
.bottom-wrapper {
    display: flex;
    justify-content: space-between;
}

.bold {
    font-weight: bold;
}
.font-14 {
    font-size: 14px;
}
.maginTop-10 {
    margin-top: 10px;
}


.text {
    font-size: 14px;
}
.d-flex {
    background-color: #fff;
    padding: 5px 5px 0px 10px;
    font-size: 14px;
}
.table-wrapper {
    height: calc(100vh - 240px);
}
.exam-title{
    font-size: 20px;
}
</style>
