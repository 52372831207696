<template>
    <div>
        <v-row no-gutters class="my-1">
            <v-col>
                <h3 class="text-center">{{ this.context.name }}</h3>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="primary" @click="back">返回</v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="pa-4">
            <div class="d-flex">
                <div class="input-width mr-2">
                    <v-text-field
                        v-model="searchParam.centreNo"
                        placeholder="请输入考场代码查询"
                        dense
                        outlined
                    ></v-text-field>
                </div>
                <v-btn color="primary" @click="getExaminationrooms">查询</v-btn>
                <v-btn   class="ml-2" color="primary" @click="allLookMonitor">
                            查看监控
                        </v-btn>
                <!-- <v-btn color="primary" class="ml-4" @click="addCentreNoClick">
                    新增
                </v-btn> -->
            </div>

            <v-data-table
                :headers="[
                    {
                        text: '考场代码',
                        value: 'centreNo',
                        width: '100px',
                    },
                    {
                        text: '考场名称',
                        value: 'name',
                        width: '100px',
                    },
                    {
                        text: '操作',
                        value: 'options',
                        width: '100px',
                    },
                ]"
                :items="examinationrooms"
                no-data-text="暂无数据"
                :items-per-page="10"
            >
                <template v-slot:item.options="{ item }">
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="RoomMonitorView(item)"
                    >
                        查看监控
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        :to="
                            $to(
                                $views.ExaminationAdmin.ExaminationRoom.Details,
                                {
                                    examId:item.examId,
                                    centreNo: item.centreNo,
                                    name: item.name,
                                }
                            )
                        "
                    >
                        详情
                    </v-btn>
                    <!-- <v-btn
                        color="primary"
                        small
                        outlined
                        class="mr-2"
                        @click="UpdateClick(item)"
                    >
                        修改
                    </v-btn>
                    <v-btn
                        color="primary"
                        small
                        outlined
                        @click="deleteClick(item)"
                    >
                        删除
                    </v-btn> -->
                </template>
            </v-data-table>
        </div>

        <v-dialog max-width="500" v-model="centreNoDialog">
            <v-card>
                <v-card-title class="headline primary white--text">
                    {{ this.filedName }}
                </v-card-title>
                <div class="d-flex mt-4 mx-6">
                    <label class="mt-2">考场代码：</label>
                    <v-text-field
                        placeholder="请输入考场代码"
                        v-model="addCentreNo.centreNo"
                        dense
                        required
                        outlined
                    />
                </div>
                <div class="d-flex mx-6">
                    <label class="mt-2">考场名称：</label>
                    <v-text-field
                        placeholder="请输入考场名称"
                        v-model="addCentreNo.name"
                        dense
                        required
                        outlined
                    />
                </div>
                <div class="text-center py-2">
                    <v-btn e2e-save-city color="primary" @click="save">
                        保存
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="ml-2"
                        outlined
                        @click="centreNoDialog = false"
                    >
                        取消
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Loading } from '@/infrastructures/decorators/Loading';

export default
@Component({})
class ExaminationRoom extends Vue {
    static name = window.$views.ExaminationAdmin.ExaminationRoom.List;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    enums;
    @Inject()
    loader;
    @Inject()
    navigation;

    filedName = '新增考场';
    isAdd = true;
    examinationrooms = [];
    searchParam = { centreNo: '', examinationSiteCode: '' };
    centreNoDialog = false;
    addCentreNo = { id: '', name: '', centreNo: '' };

    get code() {
        return this.context.code;
    }

    get name() {
        return this.context.name;
    }

    get examId(){
        return this.context.examId;
    }

    mounted() {
        this.getExaminationrooms();
    }

    allLookMonitor() {
            this.navigation.redirect(
                this.$views.Monitor.ExaminationSiteMonitor.Index,
                {
                    from: 'city',
                    areacode: this.$route.query.areacode,
                    type: 2,
                    leftMenu: true,
                    examId: this.$route.query.examId,
                    code: this.code,
                    parentCode: this.$route.query.code,
                }
            );
        }
    RoomMonitorView(room) {
        this.navigation.redirect(
            this.$views.Monitor.ExaminationRoomMonitor.Index,
            {
                examId:this.examId,
                code: this.code,
                name: this.name,
                identifier: room.centreNo,
                parentCode: this.$route.query.parentCode
            }
        );
    }

    @Loading('数据加载中...')
    getExaminationrooms() {
        let roomType = localStorage.getItem('roomType');
        this.searchParam.examinationSiteCode = this.context.code;
        this.searchParam.roomType = roomType;
        this.searchParam.examId = this.examId;
        this.repository.ExaminationRoom.GetExaminationRooms(
            this.searchParam
        ).then((res) => {
            this.examinationrooms = res.data;
        });
    }

    back() {
        this.navigation.redirect(
            this.$views.ExaminationAdmin.ExaminationSite.List,
            {
                examId:this.examId,
                areacode: this.context.areacode,
                type: this.context.type,
                areaname: this.context.areaname,
                code: this.$route.query.parentCode
            }
        );
    }

    async save() {
        let result = await this.repository.ExaminationSite.GetExistSiteArea(
            this.addCentreNo.centreNo,this.examId
        );
        if (!result.data) {
            this.notifier.warn(
                '输入的考场代码不正确（当前是在' +
                    this.context.code +
                    '的考点下，故只能新增' +
                    this.context.code +
                    'xxx这类型的考场代码）'
            );
            return;
        }

        if (this.isAdd) {
            this.AddExaminationRoom();
        } else {
            this.UpdateExaminationRoom();
        }
    }

    addCentreNoClick() {
        this.centreNoDialog = true;
        this.filedName = '新增考场';
        this.isAdd = true;
        this.addCentreNo = {};
    }

    AddExaminationRoom() {
        this.addCentreNo.examId = this.examId;
        this.repository.ExaminationRoom.AddExaminationRoom(
            this.addCentreNo
        ).then(() => {
            this.centreNoDialog = false;
            this.notifier.success('保存成功');
            this.getExaminationrooms();
        });
    }

    UpdateClick(item) {
        this.addCentreNo.id = item.id;
        this.addCentreNo.name = item.name;
        this.addCentreNo.centreNo = item.centreNo;
        this.centreNoDialog = true;
        this.filedName = '修改考场';
        this.isAdd = false;
    }

    UpdateExaminationRoom() {
        this.repository.ExaminationRoom.UpdateExaminationRoom(
            this.addCentreNo
        ).then(() => {
            this.notifier.success('修改成功');
            this.centreNoDialog = false;
            this.getExaminationrooms();
        });
    }

    deleteClick(item) {
        this.$confirm('提示', `确认要删除【${item.centreNo}】考场吗？`).then(
            () => {
                this.repository.ExaminationRoom.DelExaminationRoom({
                    id: item.id,
                }).then(() => {
                    this.notifier.success('删除成功');
                    this.getExaminationrooms();
                });
            }
        );
    }
}
</script>
