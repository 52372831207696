import Vue from 'vue';
import axios from 'axios';
import views from '@/domain/views';
import sender from '@/infrastructures/request';
import ExceptionExtension from "./extensions/exception";
import RouterExtension from "./extensions/router";
import ConfirmDialog from "../components/confirm";
import "@/assets/css/public.css";
import user from '../domain/user';
import VCharts from 'v-charts';
import 'v-charts/lib/style.css';

function getToken() {
    const urlParams = new URLSearchParams(window.location.href);
    const token = urlParams.get('token');
    if(token) {
        localStorage.setItem('roomType', -1)
        localStorage.setItem('AccessToken', token)
    }
    return token ;
}


getToken()
export default class StartUp{
    static Initialize() {
        StartUp.RegistryComponents();
        StartUp.configuration();
        StartUp.injectVars();
        StartUp.addExtensions();
        StartUp.mixin();
    }

    static injectVars() {
        window.$views = views;
        Vue.prototype.$views = views;
    }

    static configuration() {
        sender.TokenGetter = () => user.AccessToken ? user.AccessToken : getToken();
        axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';

        Vue.config.errorHandler = ExceptionExtension.ApplicationExceptionHandler;
    }

    static mixin() {
        Vue.mixin({
            computed: {
                context() {
                    return this.$route.query;
                },
            },
            methods: {
                $confirm(
                    title = '提示',
                    content,
                    yesText = '确认',
                    noText = '取消'
                ) {
                    return ConfirmDialog.Confirm(
                        title,
                        content,
                        yesText,
                        noText
                    );
                },
            }
        })
    }

    static addExtensions() {
        Vue.prototype.$to = RouterExtension.to;
        sender.ErrorHandler = ExceptionExtension.HttpExceptionHandler;
    }

    static RegistryComponents() {
        Vue.use(VCharts);
    }
}
