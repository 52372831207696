import sender from '../request';

export default class ExaminationRoomRepository {


    GetExaminationRooms(data) {
        return sender.postWithUnCatch('/ExaminationRoom/GetExaminationRooms', data);
    }


    GetEquipments(centreNo,examId) {
        return sender.getWithUnCatch('/ExaminationRoom/GetEquipmentList?centreNo='+centreNo+'&&examId='+examId);
    }

    GetOnlineEquipments(data) {
        return sender.getWithUnCatch('/ExaminationRoom/GetOnlineEquipments', data);
    }

    GetOnlineMonitorEquipments(data) {
        return sender.getWithUnCatch('/ExaminationRoom/GetOnlineMonitorEquipments', data);
    }


    GetOnlineEquipmentCount(data) {
        return sender.getWithUnCatch('/ExaminationRoom/GetOnlineEquipmentCount', data);
    }


    AddExaminationRoom(data) {
        return sender.postWithUnCatch('/ExaminationRoom/AddExaminationRoom', data);
    }

    DelExaminationRoom(data) {
        return sender.getWithUnCatch('/ExaminationRoom/DelExaminationRoom', data);
    }

    UpdateExaminationRoom(data) {
        return sender.postWithUnCatch('/ExaminationRoom/UpdateExaminationRoom', data);
    }

    GetSystemConfig(data) {
        return sender.getWithUnCatch('/ExaminationRoom/GetSystemConfig', data, 30000000);
    }

    UpdateSystemConfig(data) {
        return sender.postWithUnCatch('/ExaminationRoom/UpdateSystemConfig', data, 30000000);
    }

    GetEquipmentDetails(data) {
        return sender.getWithUnCatch('/ExaminationRoom/GetEquipmentDetails', data);
    }

    VerifyToken(code, token) {
        let data = {
            code: code,
            token: token,
        };
        return sender.new_post('/ExaminationRoom/VerificationToken', data);
    }
}
