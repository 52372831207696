var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-1",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(this.areaName))])])],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('v-row',{staticClass:"d-flex mb-n4 mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"input-width mr-2"},[_c('v-text-field',{attrs:{"placeholder":"请输入考点代码查询","dense":"","outlined":""},model:{value:(_vm.searchParam.code),callback:function ($$v) {_vm.$set(_vm.searchParam, "code", $$v)},expression:"searchParam.code"}})],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.GetExaminationSites}},[_vm._v(" 查询 ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.allLookMonitor}},[_vm._v(" 查看监控 ")])],1)])],1),_c('v-data-table',{attrs:{"headers":[
                {
                    text: '考点代码',
                    value: 'code',
                    width: '100px',
                },
                {
                    text: '考点名称',
                    value: 'name',
                    width: '100px',
                },
                {
                    text: '操作',
                    value: 'options',
                    width: '100px',
                } ],"items":_vm.examinationSites,"no-data-text":"暂无数据","items-per-page":10},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.lookMonitor(item)}}},[_vm._v(" 查看监控 ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":"","to":_vm.$to(_vm.$views.ExaminationAdmin.ExaminationRoom.List, {
                            examId:item.examId,
                            code: item.code,
                            name: item.name,
                            parentCode: _vm.$route.query.code,
                        })}},[_vm._v(" 考场管理 ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.addExaminationSiteDialog),callback:function ($$v) {_vm.addExaminationSiteDialog=$$v},expression:"addExaminationSiteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary white--text"},[_vm._v(" "+_vm._s(this.filedName)+" ")]),_c('div',{staticClass:"d-flex mt-4 mx-6"},[_c('label',{staticClass:"mt-2"},[_vm._v("考点代码：")]),_c('v-text-field',{attrs:{"placeholder":"请输入考点代码","dense":"","required":"","outlined":""},model:{value:(_vm.addExaminationSite.code),callback:function ($$v) {_vm.$set(_vm.addExaminationSite, "code", $$v)},expression:"addExaminationSite.code"}})],1),_c('div',{staticClass:"d-flex mt-4 mx-6"},[_c('label',{staticClass:"mt-2"},[_vm._v("考点名称：")]),_c('v-text-field',{attrs:{"placeholder":"请输入考点名称","dense":"","required":"","outlined":""},model:{value:(_vm.addExaminationSite.name),callback:function ($$v) {_vm.$set(_vm.addExaminationSite, "name", $$v)},expression:"addExaminationSite.name"}})],1),_c('v-divider'),_c('div',{staticClass:"text-center py-2"},[_c('v-btn',{attrs:{"e2e-save-city":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(" 保存 ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.addExaminationSiteDialog = false}}},[_vm._v(" 取消 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }