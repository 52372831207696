<template>
    <div class="pa-4" >
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="8" size="120">
                数据加载中
            </v-progress-circular>
        </v-overlay>
        <div style="position: relative; height: 50px;">
            <span>{{ title }}</span>
            <v-btn outlined style="position: absolute; top: 0px;right:0;margin-bottom: 14px;" color="primary" @click="goBack">
            返回
        </v-btn>
        </div>
        <v-data-table
            :headers="headers"
            :items="equipmentReports"
            no-data-text="暂无数据"
            :items-per-page="10"
            loading-text="Loading... Please wait"
        >
            <!-- <template v-slot:item.regsiterCount="{ item }">
                <replyProcessLinear
                    :value="Percentage(item.regsiterCount, item.totalCount)"
                ></replyProcessLinear>
            </template> -->
            <template v-slot:item.options="{ item }">
                <v-btn
                    @click="ViewDetail(item)"
                    color="primary"
                    small
                    outlined
                    class="mr-2"
                >
                    详情
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import replyProcessLinear from '@/components/replyProcessLinear/replyProcessLinear';
export default
@Component({ components: { replyProcessLinear } })
class EquipmentReports extends Vue {
    static name = window.$views.ExamCsp.ExaminationReport.RegsiterDistrictException;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    loader;
    @Inject()
    navigation;
    loading = false;
    title = ''
    equipmentReports = [];
    headers=[
        { text: '考区代码', value: 'areaCode', width: '100px', },
        { text: '考点代码', value: 'siteCode', width: '100px', },
        { text: '考场代码', value: 'centreNo', width: '100px', },
        { text: '考场名称', value: 'name', width: '100px', },
    ]

    async mounted() {
        const type = this.$route.query.type
        // alert(type===2)
        if(type===1) {
            this.title = '考场注册异常'
            await this.GetUnMonitorRoom();
        }
        if(type===2) {
            this.title = '考场上报异常'
            await this.GetUnRegisterRoom();
        }
        if(type===3) {
            this.title = '考场监控头异常'
            await this.GetUnReportRoom();
        }
    }

    goBack() {
        history.go(-1)
    }

    GetUnMonitorRoom() {
        this.loading = true;
        this.repository.ExaminationReport.GetUnMonitorRoom(
            this.context.examId
        ).then((res) => {
            this.equipmentReports = res.data;
            this.loading = false;
        });
    }

    GetUnRegisterRoom() {
        this.loading = true;
        this.repository.ExaminationReport.GetUnRegisterRoom(
            this.context.examId
        ).then((res) => {
            this.equipmentReports = res.data;
            this.loading = false;
        });
    }

    GetUnReportRoom() {
        this.loading = true;
        this.repository.ExaminationReport.GetUnReportRoom(
            this.context.examId
        ).then((res) => {
            this.equipmentReports = res.data;
            this.loading = false;
        });
    }

    ViewDetail(item) {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.RegsiterSite,
            {
                examId:this.context.examId,
                districtCode: item.areaCode,
                districtName: item.name,
            }
        );
    }

    Percentage(num, total) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Math.floor((num / total) * 10000) / 100.0;
    }
}
</script>
<style scoped>
</style>
