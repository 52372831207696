<template>
    <div class="pa-4">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate width="8" size="120">
                数据加载中
            </v-progress-circular>
        </v-overlay>
        <div style="position: relative; height: 50px;">
            <v-btn outlined style="position: absolute; top: 0px;right:0;margin-bottom: 14px;" color="primary" @click="goBack">
            返回
        </v-btn>
        </div>
        <v-data-table
            :headers="[
                {
                    text: '考区代码',
                    value: 'areaCode',
                    width: '100px',
                },
                {
                    text: '考区名称',
                    value: 'name',
                    width: '100px',
                },
                {
                    text: '考点数量',
                    value: 'siteCount',
                    width: '100px',
                },
                {
                    text: '考区监控进度',
                    value: 'mointoringCount',
                    width: '100px',
                },
                {
                    text: '操作',
                    value: 'options',
                    width: '100px',
                },
            ]"
            :items="equipmentReports"
            no-data-text="暂无数据"
            :items-per-page="10"
            loading-text="Loading... Please wait"
        >
            <template v-slot:item.mointoringCount="{ item }">
                <replyProcessLinear
                    :value="Percentage(item.mointoringCount, item.totalCount)"
                ></replyProcessLinear>
            </template>
            <template v-slot:item.options="{ item }">
                <v-btn
                    @click="ViewDetail(item)"
                    color="primary"
                    small
                    outlined
                    class="mr-2"
                >
                    详情
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import replyProcessLinear from '@/components/replyProcessLinear/replyProcessLinear';
export default
@Component({ components: { replyProcessLinear } })
class EquipmentReports extends Vue {
    static name = window.$views.ExamCsp.ExaminationReport.MonitorDistrict;
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    loader;
    @Inject()
    navigation;
    equipmentReports = [];
    loading = false;
    mounted() {
        this.GetExaminationAreaReport();
    }

    goBack() {
        history.go(-1)
    }

    GetExaminationAreaReport() {
        let roomType = localStorage.getItem('roomType');
        this.loading = true;
        this.repository.ExaminationReport.GetExaminationAreaReport(
            this.context.examId,
            roomType
        ).then((res) => {
            this.equipmentReports = res.data;
            this.loading = false;
        });
    }

    ViewDetail(item) {
        this.navigation.redirect(
            window.$views.ExamCsp.ExaminationReport.MonitorSite,
            { districtCode: item.areaCode, districtName: item.name,examId:this.context.examId}
        );
    }

    Percentage(num, total) {
        if (num == 0 || total == 0) {
            return 0;
        }
        return Math.floor((num / total) * 10000) / 100.0;
    }
}
</script>
<style scoped>
</style>
