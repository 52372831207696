<template>
    <div style="height: 100%">
        <v-toolbar dense dark>
            <v-row class="text-right" no-gutters>
                <v-col class="title"> {{ title }}</v-col>
            </v-row>
            <v-spacer></v-spacer>
            <slot name="options">
                <v-btn color="white" class="mr-2" outlined @click="prev">
                    上一页
                </v-btn>
                <v-btn color="white" class="mr-2" outlined @click="next">
                    下一页
                </v-btn>
            </slot>
        </v-toolbar>
        <v-row no-gutters>
            <div v-for="(item, i) in 9" :key="i"  id="equipments"
            :index="i"
            class="video-item" :style="playerStyle"
            :class="i<items.length?'show':'hidden'"
            >
                <video-player
                    class="monitor-div"
                    :playerstyle="player"
                    :url="GetPlayerUrl(i)"
                    :vid="'tcplayer'+i"
                    :enableFil="enableFil"
                >
                    <template #title>
                        <!-- {{ item.examinationRoomCode }}
                        <span>{{ item.examinationRoomName }}</span>
                        <p class="float-right px-2">{{ nowDate }}</p> -->
                        <div class="RoomTitle">
                            <div class="RoomTitle-L">
                                <span class="s">{{ GetRoomCode(i) }} </span> 
                                <span class="s">{{ GetRoomRoomName(i) }} </span>
                                <span class="s">{{ GetRoomPosition(i) }}</span>
                            </div>
                            <div class="RoomTitle-R">
                                {{ nowDate }}
                            </div>
                        </div>
                    </template>
                </video-player>
            </div>
        </v-row>
    </div>
</template>

<script>
    import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
    import VideoPlayer from '@/components/videoPlayer/index';
    import Timer from '@/infrastructures/timer/timer';
    export default
    @Component({
        components: {
            VideoPlayer,
        },
    })
    class MonitorViewComponent extends Vue {
        @Prop() items;
        @Prop() title;
        @Prop() width;
        @Prop() height;
        @Prop() enableFil;

        get playerStyle() {
            return {
                width: `${this.player.width}px`,
                height: `${this.player.height}px`,
            };
        }

        mounted() {
            this.$nextTick(() => {
                this.timer.Start();
                this.OnItemsChanged(this.items);
            });
        }

        destroyed() {
            if (this.timer) {
                this.timer.Stop();
            }
        }

        @Watch('items', { immediate: true, deep: true })
        OnItemsChanged(val) {
            let count = val.length,
                width = this.width ?? this.$el.clientWidth,
                height = (this.height ?? this.$el.clientHeight) - 48; //减去header高度
            this.Calc(count, width, height);
        }

        Calc(count, totalWidth, totalHeight) {
            let row = 1,
                col = 1;
            if (count <= 1) {
                row = 1;
                col = 1;
            } else if (count <= 2) {
                col = 2;
            } else if (count <= 4) {
                col = 2;
                row = 2;
            } else if (count <= 6) {
                col = 3;
                row = 2;
            } else if (count <= 9) {
                col = 3;
                row = 3;
            }

            this.player.width = totalWidth / col;
            this.player.height = totalHeight / row;
        }

        GetRoomCode(i) {
            const roomCode = this.items.length > i ? this.items[i].examinationRoomCode : null;  // eslint-disable-line
            return roomCode
        }

        GetRoomRoomName(i) {
            const roomName = this.items.length > i ? this.items[i].examinationRoomName : null;  // eslint-disable-line
            return roomName
        }

        GetRoomPosition(i) {
            var positionDesc = this.items.length > i ? this.items[i].positionDesc : null;  // eslint-disable-line
            if(positionDesc && this.items[i].examinationRoomType==1)
                positionDesc='';
            return positionDesc
        }

        GetPlayerUrl(i) {
            // return `${document.location.protocol}//player.live.zfoline.net/${userId}.m3u8`;
            const userId =  this.items.length > i ? this.items[i].userId : null;
            return `${document.location.protocol}//player.live.zfoline.net/${userId}.m3u8`;
        }

        formatDate() {
            let date = new Date();
            let year = date.getFullYear(); // 年
            let month = date.getMonth() + 1; // 月
            let day = date.getDate(); // 日
            let week = date.getDay(); // 星期
            let weekArr = [
                '星期日',
                '星期一',
                '星期二',
                '星期三',
                '星期四',
                '星期五',
                '星期六',
            ];
            let hour = date.getHours(); // 时
            hour = hour < 10 ? '0' + hour : hour;
            let minute = date.getMinutes(); // 分
            minute = minute < 10 ? '0' + minute : minute;
            let second = date.getSeconds(); // 秒
            second = second < 10 ? '0' + second : second;
            let currentDate = date.getDate();
            day = currentDate < 10 ? ('0' + currentDate) : currentDate;
            this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
        }

        @Emit()
        prev() {}

        @Emit()
        next() {}

        player = {
            width: 640,
            height: 360,
        };
        nowDate = '';
        timer = Timer.CreateFromSecond(this.formatDate, 1);
    }
</script>

<style lang="scss" scoped>
    .monitor-div {
        position: relative;
    }
    .no-gutters {
        display: flex;
        .video-item {
            width: 33.3%;
            position: relative;
            border: 1px solid rgba(255,255,255,0.05);
        }
        .show {
            display: block;
        }
        .hidden {
            display: none;
            // position: absolute;
            // left: 0;
            // top: 0;
            // z-index: -1;
        }
    }
</style>
