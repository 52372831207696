import ExaminationRoomRepository from '@/infrastructures/repositories/ExaminationRoomRepository';
import VideoRepository from '@/infrastructures/repositories/VideoRepository';
import ExaminationSiteRepository from '@/infrastructures/repositories/ExaminationSiteRepository';
import ExaminationDistrictRepository from '@/infrastructures/repositories/ExaminationDistrictRepository';
import ExaminationReportRepository from '@/infrastructures/repositories/ExaminationReportRepository';
import OrganizationRepository from '@/infrastructures/repositories/OrganizationRepository';
import UserRepository from '@/infrastructures/repositories/UserRepository';
import ExamRepository from '@/infrastructures/repositories/ExamRepository';

const repository = {
    ExaminationRoom: new ExaminationRoomRepository(),
    Video: new VideoRepository(),
    ExaminationSite: new ExaminationSiteRepository(),
    ExaminationDistrict: new ExaminationDistrictRepository(),
    ExaminationReport:new ExaminationReportRepository(),
    Organization:new OrganizationRepository(),
    User:new UserRepository(),
    Exam:new ExamRepository()
};

export default repository;
