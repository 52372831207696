import sender from '../request';
export default class ExamRepository {

    GetExamList(data) {
        return sender.get("/Exam/GetExams", data)
    }

    AddOrUpdate(data) {
        return sender.postWithUnCatch("/Exam/AddOrUpdate", data)
    }

    DelExam(id) {
        return sender.postWithUnCatch("/Exam/DelExam",{Id:id})
    }

    UpdExamStatus(data) {
        return sender.postWithUnCatch("/Exam/UpdExamStatus", data)
    }
    SetMonitorInfo(data) {
        return sender.postWithUnCatch("/Exam/SetMonitorInfo", data)
    }
    ToppingExam(data) {
        return sender.postWithUnCatch("/Exam/ToppingExam", data)
    }

    CancelToppingExam(data) {
        return sender.postWithUnCatch("/Exam/CancelToppingExam", data)
    }
}
