var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"8","size":"120"}},[_vm._v(" 数据加载中 ")])],1),_c('div',{staticStyle:{"position":"relative","height":"50px"}},[_c('v-btn',{staticStyle:{"position":"absolute","top":"0px","right":"0","margin-bottom":"14px"},attrs:{"outlined":"","color":"primary"},on:{"click":_vm.goBack}},[_vm._v(" 返回 ")])],1),_c('v-data-table',{attrs:{"headers":[
            {
                text: '考区代码',
                value: 'areaCode',
                width: '100px',
            },
            {
                text: '考区名称',
                value: 'name',
                width: '100px',
            },
            {
                text: '考点数量',
                value: 'siteCount',
                width: '100px',
            },
            {
                text: '考区注册进度',
                value: 'regsiterCount',
                width: '100px',
            },
            {
                text: '操作',
                value: 'options',
                width: '100px',
            } ],"items":_vm.equipmentReports,"no-data-text":"暂无数据","items-per-page":10,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.regsiterCount",fn:function(ref){
        var item = ref.item;
return [_c('replyProcessLinear',{attrs:{"value":_vm.Percentage(item.regsiterCount, item.totalCount)}})]}},{key:"item.options",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.ViewDetail(item)}}},[_vm._v(" 详情 ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }