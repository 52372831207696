<template>
    <v-treeview
        activatable
        :open.sync="open"
        :items="items"
        hoverable
        item-key="name"
        open-on-click
        :load-children="loadChildren"
        return-object
    >
        <template v-slot:prepend="{ item }">
            <div v-if="item.type === 3">
                <v-icon>mdi-video</v-icon>
            </div>
        </template>
        <template v-slot:label="{ item }">
            <div @click="view(item)">{{ item.name }}</div>
        </template>
    </v-treeview>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import { Loading } from '@/infrastructures/decorators/Loading';
export default
@Component({})
class examinatintree extends Vue {
    @Inject()
    repository;
    @Inject()
    notifier;
    @Inject()
    loader;
    @Inject()
    navigation;
    @Inject()
    enums;

    items = [];
    open = ['public'];
    files = {
        play: 'mdi-video',
    };

    mounted() {
        this.GetExaminationTreeData();
    }

    @Loading('数据加载中...')
    GetExaminationTreeData() {
        let roomType = localStorage.getItem('roomType') || -1 ;
        this.repository.ExaminationDistrict.GetExaminationAreaTree(
            this.context.examId,
            roomType
        ).then((res) => {
            res.data.forEach((el) => {
                if (el.hasChildren) {
                    el.children = [];
                }
            });
            this.items = res.data;
        });
    }

    view(item) {
        this.$emit('func', item);
    }

    GetRoomTree(item) {
        let roomType = localStorage.getItem('roomType');
        return new Promise((resolve, reject) => {
            this.repository.ExaminationDistrict.GetRoomTreeInfo(
                item.id,
                roomType
            ).then((res) => {
                res.data.forEach((el) => {
                    if (el.hasChildren) {
                        el.children = [];
                    }
                    item.children.push(el);
                });
                resolve(item);
                reject();
            });
        });
    }

    GetSiteTree(item) {
        let roomType = localStorage.getItem('roomType');
        return new Promise((resolve, reject) => {
            this.repository.ExaminationDistrict.GetSiteTreeInfo(
                item.id,
                roomType
            ).then((res) => {
                res.data.forEach((el) => {
                    if (el.hasChildren) {
                        el.children = [];
                    }
                    item.children.push(el);
                });
                resolve(item);
                reject();
            });
        });
    }

    GetEquipmentTree(item) {
        return new Promise((resolve, reject) => {
            this.repository.ExaminationDistrict.GetEquipmentTreeInfo(
                item.id
            ).then((res) => {
                res.data.forEach((el) => {
                    if (el.hasChildren) {
                        el.children = [];
                    }
                    item.children.push(el);
                });
                resolve(item);
                reject();
            });
        });
    }
    async loadChildren(item) {
        if (item.treeType == this.enums.TreeType.考区.value) {
            return await this.GetSiteTree(item);
        }
        if (item.treeType == this.enums.TreeType.考点.value) {
            return await this.GetRoomTree(item);
        }
        if (item.treeType == this.enums.TreeType.考场.value) {
            return await this.GetEquipmentTree(item);
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .v-treeview-node__content {
    margin-left: -30px !important;
}
::v-deep v-treeview-node__prepend{
    padding-left: 5px !important;
}
</style>
